.upload_Container{
    // position: relative;
    width: 70%;
    float: left;
    // width: 88px;
    // height: 32px;
}
.upload_Container .loadVideo{
    position: relative;
    display: block;
    // width: 100%;
    // height: 100%;
}
.loadVideo .fileVideo{
    width: 88px;
    height: 32px;
    z-index: 9999;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
}
.loadVideo{
    margin-top: 10px;
}
.deletStyle{
    display: inline-block;
    width: 40px;
    height: 20px;
    line-height: 20px;
    font-size: 13px;
    text-align: center;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    background: rgb(230, 36, 36);
    cursor: pointer;
    z-index: 999;
}
.imgSize{
    width: 100%;
    height: 20px;
    line-height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #fff;
    background: rgba(0,0,0,0.6);
    font-size: 10px;
    font-weight: bold;
    text-align: center;
}