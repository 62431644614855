.vipModal_item {
    width: 90%;
    padding: 12px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #e8e8e8;
    .vipModal_title {
        font: {
            weight: bold;
        }
    }
}
