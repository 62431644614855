.vipOrderCount_wrap {
    width: 100%;
    display: flex;
    background-color: #1890ff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .vipOrderCount_item {
        flex-grow: 1;
        text-align: center;
        color: #fff;
        p {
            margin: 10px 0;
            &:nth-child(1) {
                font: {
                    weight: bold;
                    size: 20px;
                }
            }
            &:nth-child(2) {
                font-size: 16px;
            }
        }
    }
}
