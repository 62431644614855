body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td{margin:0;padding:0;}
ol,ul{list-style: none}
button, input{ outline: none }
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container{
  width: 100%;
  position: relative;
}

.clearfix:after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  visibility: hidden; }
.inputBox{
  position: relative;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  font-size: 18px;
  .starInfo{
    font-size: 16px;
    margin-bottom: 0;
    padding: 3px 0;
    color: #808080;
  }
}
.inputName{
  width: 20%;
  line-height: 28px;
  display: block;
  font-size: 16px;
  font-weight: bold;
  p:nth-child(2){
    font-size: 10px;
    font-weight: normal;
    padding-right: 20px;
  }
  p{
    margin-bottom: 0;
    
  }
  // float: left;
}
.ant-input{
  width: 40%;
}
#tags{
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-radio-button-wrapper{
  margin: 0 10px 10px 0 !important;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}