.permItem_wrap{
    width: 100%;
    .permItem_item{
        float: left;
        padding: 10px;
        &:nth-child(1){
            width: 34%;
            border-right: 1px solid #e8e8e8;
        }
        &:nth-child(2){
            width: 66%;
        }
        .perItem_box{
            width: 100%;
            .perItem_title{
                line-height: 28px;
                font-size: 16px;
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }
    }
}