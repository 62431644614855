.vipCancel_item {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
    .vipCancel_title {
        font: {
            size: 16px;
            weight: bold;
        }
    }
    .vipCancel_main{
        margin-left: 20px;
    }
}
