.starThumb{
    width: 100px;
    height: 100px;
    overflow: hidden;
    .starAvaThumb{
        width: 100%;
        position: relative;
        .starAva{
            width: 100%;
            border-radius: 100%;
            overflow: hidden;
            img{
                width: 100%;
                display: block;
            }
        }
        .starSymbol{
            width: 100%;
            position: absolute;
            bottom:0;
            img{
                width: 100%;
                display: block;
            }
        }
    }
}