.edit_container{
    width: 100%;
    .edit_wrap{
        min-width: 900px;
        padding: 3vh 0;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: #f9f9f9;
        z-index: 1001;
        transition: all 200ms ease;
        .edit_main{
            width: 100%;
            position: relative;
            display: flex;
            // edece8
            .edit_con{
                width: 48%;
                border-right: 1px solid #eee;
                .editorSty{
                    width: 100%;
                }
                .cancelBtn{
                    float: right;
                }
            }
            .edit_phoneCon{
                width: 50%;
                background: #edece8;
                .edit_phoneMain{
                    width: 375px;
                    height: 667px;
                    overflow-y: scroll;
                    margin: 0 auto;
                    background: #fff;
                    padding: 2vh 1vh;
                    .Album_head{
                        line-height: 40px;
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                    .Album_text{
                        font-size: 15px;
                        line-height: 25px;
                        margin-bottom: 10px;
                    }
                    .Album_img{
                        width: 80%;
                        margin: 0 auto;
                        img{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
