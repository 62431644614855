.ant-layout.ant-layout-has-sider {
  min-height: 100vh;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  width: 80%;
  overflow: hidden;
  margin: 16px;
  text-align: center;
  font-size: 16px;
  color: #1890ff;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 700;
}
.div {
  width: 64px;
  height: 64px;
  text-align: center;
  background: transparent;
  font-size: 18px;
  float: left;
}

.home_info {
  height: 100%;
  margin-right: 120px;
  display: flex;
  align-items: center;
  &_nick {
    height: 100%;
    margin-left: 10px;
    &_text {
      height: 20px;
      line-height: 20px;
      margin-bottom: 0;
      margin-top: 6px;
    }
    &_btn{
      width: 90px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background-color: #FF4D4F;
      color: #fff;
      margin-top: 5px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
